const getters = {
  unreadNotificationCount: state => state.notification.unreadCount,
  isNeedCheckAutoAlertNotification: state => state.notification.isNeedCheckAutoAlertNotification,
  autoAlertNotificationId: state => state.notification.autoAlertNotificationId,
  userId: state => state.user.id,
  userFullName: state => state.user.fullName,
  userRole: state => state.user.role,
  permissions: state => state.user.permissions,
  dynamicBreadCrumbs: state => state.app.dynamicBreadCrumbs,
  currentMenu: state => state.app.currentMenu
}

export default getters

import axios from 'axios'
import store from '@/store'
import { alertMessage } from '@/utils/alert_message'
import {
  setToken,
  getRequestToken,
  ACCESS_TOKEN,
  NEW_TOKEN,
  CLIENT_HEADER_KEY,
  CLIENT_HEADER_VALUE
} from '@/utils/token'
import { INVALID_TOKEN_CODE, USER_LOCKED_CODE } from '@/utils/api_code'
import router from '@/router'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 120 * 1000 // 请求超时时间
})

// request interceptor
request.interceptors.request.use(
  config => {
    // 设置客户端 header
    config.headers[CLIENT_HEADER_KEY] = CLIENT_HEADER_VALUE

    const token = getRequestToken()
    // 如果 token 存在, 让每个请求携带自定义 token
    if (token) {
      config.headers[ACCESS_TOKEN] = token
    }
    return config
  },
  error => {
    alertMessage({
      type: 'error',
      content: error.message
    })
    return Promise.reject(error)
  })

// response interceptor
request.interceptors.response.use(
  response => {
    const res = response.data

    if (res.message) {
      alertMessage({
        ...res.message,
        key: `${res.code}${res.message.type}${res.message.content}`
      })
    }

    if (res.code === INVALID_TOKEN_CODE || res.code === USER_LOCKED_CODE) {
      store.dispatch('ClearLoginInfo').then(() => {
        router.push({ name: 'login' }).catch(() => {})
      })
    }

    // 检测到headers中有刷新的token，则设置新token
    if (response.headers[NEW_TOKEN]) {
      setToken(response.headers[NEW_TOKEN])
    }

    return res
  },
  error => {
    alertMessage({
      type: 'error',
      content: error.message
    })
    return Promise.reject(error)
  })

export default request

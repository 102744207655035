<!-- 基础layout 由顶部导航栏、菜单栏、主题内容(未定义样式，可自定义内容部分样式) 组成 -->
<!-- 与 BasicLayout 不同在于 主题内容可自定义样式 -->
<template>
  <a-layout>
    <!-- 顶部 navbar -->
    <header-navbar />
    <!-- 菜单 -->
    <header-menu />
    <!-- 主体内容 -->
    <custom-style-content-wrapper />
  </a-layout>
</template>

<script>

import HeaderMenu from '@/components/HeaderMenu'
import HeaderNavbar from '@/components/HeaderNavbar'
import CustomStyleContentWrapper from '@/components/ContentWrapper/CustomStyle'

export default {
  name: 'BasicLayout',
  components: {
    CustomStyleContentWrapper,
    HeaderNavbar,
    HeaderMenu
  }
}
</script>

import Home from '@/views/home'
import CustomStyleLayout from '@/layouts/CustomStyleLayout'

// 基础路由(不受权限控制的，所有角色都有的路由)
export const constantRouter = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: { title: '欢迎登录' }
  },
  {
    path: '/',
    component: CustomStyleLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
        meta: { title: '首页', menu: 'home' }
      }
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/exception/404')
  }
]

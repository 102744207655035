<template>
  <a-layout-content class="content-wrapper">
    <bread-crumb />
    <div class="simiot-content-box">
      <router-view />
    </div>
  </a-layout-content>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'

export default {
  name: 'ContentWrapper',
  components: { BreadCrumb }
}
</script>

<style lang="less" scoped>
.content-wrapper {
  padding: 0 50px 30px 50px;
  overflow: auto;
  height: calc(100vh - 146px);
}
</style>

<!-- 基础layout 由顶部导航栏、菜单栏、主题内容(白色背景) 组成-->
<template>
  <a-layout>
    <!-- 顶部 navbar -->
    <header-navbar />
    <!-- 菜单 -->
    <header-menu />
    <!-- 主体内容 -->
    <content-wrapper />
  </a-layout>
</template>

<script>

import HeaderMenu from '@/components/HeaderMenu'
import HeaderNavbar from '@/components/HeaderNavbar'
import ContentWrapper from '@/components/ContentWrapper'

export default {
  name: 'BasicLayout',
  components: {
    ContentWrapper,
    HeaderNavbar,
    HeaderMenu
  }
}
</script>

import lodash from 'lodash'

const app = {
  state: {
    dynamicBreadCrumbs: {
      routes: [], // 匹配到 title 的路由
      placeholder: {} // 动态替换的值, 如 { ':month': '202106' }
    }, // 动态面包屑
    currentMenu: [] // 当前选中的菜单
  },

  mutations: {
    SET_DYNAMIC_BREAD_CRUMBS: (state, routes) => {
      // 面包屑对应的路由没有变化时，面包屑不变
      let breadcrumbChanged = false
      if (state.dynamicBreadCrumbs.routes.length !== routes.length) {
        breadcrumbChanged = true
      } else {
        state.dynamicBreadCrumbs.routes.some((route, index) => {
          if (!lodash.isEqual(route.meta, routes[index].meta) ||
            !lodash.isEqual(route.regex, routes[index].regex) ||
            route.name !== routes[index].name ||
            route.path !== routes[index].path) {
            breadcrumbChanged = true
            return true
          }
        })
      }

      state.dynamicBreadCrumbs.routes = routes
      // 面包屑变更了，清空 placeholder
      if (breadcrumbChanged) {
        state.dynamicBreadCrumbs.placeholder = {}
      }
    },
    CONCAT_DYNAMIC_BREAD_CRUMBS: (state, routes) => {
      state.dynamicBreadCrumbs.routes = state.dynamicBreadCrumbs.routes.concat(routes)
    },
    SET_DYNAMIC_BREAD_CRUMBS_PLACEHOLDER: (state, placeholder) => {
      state.dynamicBreadCrumbs.placeholder = placeholder
    },
    SET_CURRENT_MENU: (state, menu) => {
      state.currentMenu = menu
    }
  },

  actions: {
    // 设置面包屑
    SetDynamicBreadCrumbs({ commit }, routes) {
      return new Promise(resolve => {
        commit('SET_DYNAMIC_BREAD_CRUMBS', routes)
        resolve()
      })
    },

    // 追加设置面包屑(常用于面包屑生成规则不统一，自行生成面包屑)
    ConcatDynamicBreadCrumbs({ commit }, routes) {
      return new Promise(resolve => {
        commit('CONCAT_DYNAMIC_BREAD_CRUMBS', routes)
        resolve()
      })
    },

    // 设置面包屑 placeholder(替换占位符的值)
    SetDynamicBreadCrumbsPlaceholder({ commit }, placeholder) {
      return new Promise(resolve => {
        commit('SET_DYNAMIC_BREAD_CRUMBS_PLACEHOLDER', placeholder)
        resolve()
      })
    },

    // 设置当前路由名称
    SetCurrentMenu({ commit }, menu) {
      return new Promise(resolve => {
        commit('SET_CURRENT_MENU', menu)
        resolve()
      })
    }
  }
}

export default app

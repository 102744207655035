<template>
  <div class="box">
    <a-spin :spinning="loading">
      <a-row :gutter="16">
        <a-col :span="7">
          <a-space>
            <img
              src="@/assets/images/agents_icon.svg"
              alt=""
              width="60"
            >
            <a-statistic
              :value="agents_count"
              class="simiot-statistic"
            >
              <template slot="title">
                <a-tooltip>
                  <template slot="title">
                    统计产生账单并计入报表统计的分销商数量
                  </template>
                  客户数
                  <a-icon type="question-circle" />
                </a-tooltip>
              </template>
            </a-statistic>
          </a-space>
        </a-col>

        <a-col :span="7">
          <a-statistic
            title="待处理注册客户数"
            :value="pending_registered_agents_count"
            class="simiot-statistic"
          />
        </a-col>

        <a-col :span="7">
          <a-statistic
            title="累计余额(元)"
            :value="accumulated_balance"
            :precision="2"
            class="simiot-statistic"
          />
        </a-col>
      </a-row>

      <div
        ref="agentsStatisticsChart"
        id="agentsStatisticsChart"
        style="height: 240px;"
      />
    </a-spin>
  </div>
</template>

<script>
import { findHomeAgentsStatistics } from '@/api/home'

export default {
  name: 'AgentsStatistics',
  data() {
    // 节流
    this.handleWindowResize = this.$lodash.throttle(this.handleWindowResize, 1000)
    return {
      loading: true,
      agents_count: 0, // 客户总数
      pending_registered_agents_count: 0, // 待处理注册客户数
      accumulated_balance: 0, // 累计余额
      statistics: [], // 图表数据
      chart: null
    }
  },
  created() {
    window.addEventListener('resize', this.handleWindowResize)
  },
  mounted() {
    this.fetchData()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  computed: {
    // 指定图表的配置项和数据
    chartOption() {
      const colors = [this.$primaryColor]
      return {
        color: colors,
        tooltip: {
          trigger: 'axis'
        },
        title: {
          text: '最近一年客户增长趋势',
          left: 'center',
          bottom: '0',
          textStyle: {
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 'normal'
          }
        },
        grid: {
          left: 0,
          right: 50,
          bottom: 35,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            name: '月份',
            axisTick: {
              alignWithLabel: true
            },
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '个数',
            min: 0,
            minInterval: 1
          }
        ],
        series: [
          {
            name: '新增客户数',
            type: 'line',
            data: []
          }
        ]
      }
    }
  },
  methods: {
    handleWindowResize() {
      this.chart && this.chart.resize()
    },

    // 根据数据绘制图表
    drawChart() {
      this.chart ||= this.$echarts.init(this.$refs.agentsStatisticsChart)
      // 横坐标月份
      this.chartOption.xAxis[0].data = this.$lodash.map(this.statistics, 'month')
      // 客户个数
      this.chartOption.series[0].data = this.$lodash.map(this.statistics, 'count')
      // 使用刚指定的配置项和数据显示图表
      this.chart.setOption(this.chartOption)
    },

    fetchData() {
      this.loading = true
      findHomeAgentsStatistics(this.id, this.query).then((res) => {
        if (res.code === 0) {
          this.agents_count = res.data.agents_count
          this.pending_registered_agents_count = res.data.pending_registered_agents_count
          this.accumulated_balance = res.data.accumulated_balance
          this.statistics = res.data.statistics
          this.drawChart()
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  height: 300px;
}
</style>

<template>
  <div class="box">
    <a-row type="flex" justify="space-between">
      <a-col :span="4">
        <div class="card-title">
          通知消息
        </div>
      </a-col>

      <a-col
        :span="4"
        class="more-notifications"
      >
        <router-link to="/notifications">查看全部</router-link>
      </a-col>
    </a-row>

    <a-spin :spinning="loading">
      <div class="notifications-box" v-if="this.data.length > 0">
        <div
          v-for="item in this.data"
          :key="item.id"
          @click="showInfoModal(item.id)"
          class="notification simiot-cursor-pointer"
        >
          <span class="title" :class="{ 'unread': !item.is_read }">{{ item.title }}</span>
          <span class="time">{{ item.published_at }}</span>
        </div>
      </div>
      <a-empty v-else />
    </a-spin>

    <show-notification
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
      @alreadyRead="alreadyRead"
    />
  </div>
</template>

<script>
import { findHomeRecentNotifications } from '@/api/home'

export default {
  name: 'RecentNotifications',
  components: {
    ShowNotification: () => import('@/views/notifications/Show')
  },
  data() {
    return {
      data: [],
      isShowInfoModal: false, // 是否显示详情弹窗
      showingId: 0, // 正在查看详情的记录id
      loading: true
    }
  },
  watch: {
    // 自动弹出的通知已读后, 更新当前展示的通知消息状态
    autoAlertNotificationId() {
      this.alreadyRead(this.autoAlertNotificationId)
    }
  },
  computed: {
    autoAlertNotificationId() {
      return this.$store.getters.autoAlertNotificationId
    },

    isNeedCheckAutoAlertNotification() {
      return this.$store.getters.isNeedCheckAutoAlertNotification
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findHomeRecentNotifications({ is_pull: this.isNeedCheckAutoAlertNotification }).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    },

    showInfoModal(id) {
      this.showingId = id
      this.isShowInfoModal = true
    },

    // 已读后更新其他处的通知消息状态
    alreadyRead(id) {
      const objIndex = this.$lodash.findIndex(this.data, { id: id })
      const obj = this.data[objIndex]
      if (!obj || obj.is_read) {
        return
      }

      // 将列表的这一条通知设置为已读
      obj.is_read = true
      this.$set(this.data, objIndex, obj)

      this.$pullNotifications()
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  height: 300px;
  .card-title {
    color: @primary-color;
    font-size: 16px;
  }

  .more-notifications {
    text-align: right;
  }

  .notifications-box {
    padding: 10px 0;
    line-height: 33px;
    overflow: hidden;
    height: 276px;

    .notification {
      display: flex;
      justify-content: space-between;

      &:hover {
        color: @primary-color;
      }

      .title {
        // 未读小圆点
        &.unread:before {
          content: '';
          position: absolute;
          left: 0;
          top: 12px;
          width: 8px;
          height: 8px;
          background-color: @primary-color;
          border-radius: 4px;
        }

        position: relative;
        padding-left: 15px;
        width: calc(100% - 200px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .time {
        width: 170px;
        text-align:right;
      }
    }
  }
}
</style>

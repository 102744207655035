<template>
  <div class="home">
    <a-row :gutter="[16,16]">
      <a-col span="12">
        <div class="simiot-card-box">
          <agents-statistics />
        </div>
      </a-col>

      <a-col span="12">
        <div class="simiot-card-box">
          <recent-notifications />
        </div>
      </a-col>
    </a-row>

    <a-row :gutter="[16,16]">
      <a-col span="24">
        <div class="simiot-card-box">
          <cards-count-statistics />
        </div>
      </a-col>
    </a-row>

    <a-row :gutter="[16,16]">
      <a-col span="12">
        <div class="simiot-card-box">
          <current-month-operational-cards-statistics />
        </div>
      </a-col>

      <a-col span="12">
        <div class="simiot-card-box">
          <flow-usage-statistics />
        </div>
      </a-col>
    </a-row>

  </div>
</template>

<script>
import AgentsStatistics from '@/views/home/AgentsStatistics'
import CardsCountStatistics from '@/views/home/CardsCountStatistics'
import CurrentMonthOperationalCardsStatistics from '@/views/home/CurrentMonthOperationalCardsStatistics'
import FlowUsageStatistics from '@/views/home/FlowUsageStatistics'
import RecentNotifications from '@/views/home/RecentNotifications'

export default {
  name: 'Home',
  components: {
    RecentNotifications,
    FlowUsageStatistics,
    CurrentMonthOperationalCardsStatistics,
    CardsCountStatistics,
    AgentsStatistics
  }
}
</script>

<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
      <check-app-version />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'

export default {
  components: {},
  data() {
    return {
      locale: zhCN // 使用中文包
    }
  }
}
</script>

<template>
  <div class="box">
    <a-spin :spinning="loading">
      <div class="card-title">
        本月卡运营数据统计
      </div>

      <a-row :gutter="24">
        <a-col span="24">
          <div
            class="chart"
            ref="currentMonthOperationalCardsStatisticsChart"
          />
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import { findHomeCurrentMonthOperationalCardsStatistics } from '@/api/home'

export default {
  name: 'CurrentMonthOperationalCardsStatistics',
  data() {
    this.handleWindowResize = this.$lodash.throttle(this.handleWindowResize, 1000)
    return {
      loading: true,
      chartOption: {
        color: ['#379af5'],
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: 10,
          right: 0,
          bottom: 0,
          containLabel: true
        },
        xAxis:
          {
            type: 'category',
            axisLabel: { // 坐标轴刻度标签的相关设置
              interval: 0,
              rotate: 20 // 倾斜20度
            },
            data: [],
            axisTick: {
              alignWithLabel: true
            }
          },
        yAxis: [
          {
            type: 'value',
            name: '单位: 张',
            min: 0,
            minInterval: 1
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barWidth: '60%',
            data: []
          }
        ]
      },
      chart: null,
      statistics: [] // 图表数据
    }
  },
  created() {
    window.addEventListener('resize', this.handleWindowResize)
  },
  mounted() {
    this.fetchData()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  methods: {
    handleWindowResize() {
      this.chart && this.chart.resize()
    },

    // 根据数据绘制图表
    drawChart() {
      this.chart ||= this.$echarts.init(this.$refs.currentMonthOperationalCardsStatisticsChart)
      this.chartOption.xAxis.data = this.$lodash.map(this.statistics, 'name')
      this.chartOption.series[0].data = this.$lodash.map(this.statistics, 'count')
      this.chart.setOption(this.chartOption)
    },

    fetchData() {
      this.loading = true
      findHomeCurrentMonthOperationalCardsStatistics(this.id, this.query).then((res) => {
        if (res.code === 0) {
          this.statistics = res.data.statistics
          this.drawChart()
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  height: 300px;
  .card-title {
    color: @primary-color;
    font-size: 16px;
  }

  .chart {
    height: 300px;
  }
}
</style>

import { login, findUserInfo, logout } from '@/api/login'
import { clearStorage, getToken, setToken } from '@/utils/token'

const user = {
  state: {
    id: 0,
    fullName: '',
    role: '',
    permissions: []
  },

  mutations: {
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_USER_INFO: (state, {
      id,
      fullName,
      role
    }) => {
      state.id = id
      state.fullName = fullName
      state.role = role
    },
    CLEAR_USER_INFO: (state) => {
      state.id = 0
      state.fullName = ''
      state.role = ''
    }
  },

  actions: {
    // 清除登录信息
    ClearLoginInfo({ commit }) {
      return new Promise(resolve => {
        commit('SET_PERMISSIONS', [])
        commit('CLEAR_USER_INFO', [])
        commit('CLEAR_NOTIFICATION_DATA')
        clearStorage()
        resolve()
      })
    },

    // 登录
    Login({ dispatch, commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(res => {
          if (res.code === 0) {
            dispatch('ClearLoginInfo')
            setToken(res.data.token)
            dispatch('SetIsNeedCheckAutoAlertNotification', true)
            resolve(res)
          } else {
            reject(res)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    FindUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        findUserInfo().then(res => {
          if (res.code === 0) {
            const { data } = res
            commit('SET_PERMISSIONS', data.permissions)
            commit('SET_USER_INFO', {
              id: data.id,
              fullName: data.full_name,
              role: data.role
            })
            resolve(res)
          } else {
            reject(res)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout({ dispatch }) {
      return new Promise((resolve) => {
        logout(getToken()).then((res) => {
          if (res.code === 0) {
            dispatch('ClearLoginInfo')
            resolve(res)
          }
        }).catch((err) => {
          resolve(err)
        }).finally(() => {
        })
      })
    }
  }
}

export default user
